export const appRed = "#F54F64";
export const appBrown = "#5c4324";
export const appBlack = "#17202A";
export const appPurple = "#4A235A";

export const xSpace = { xs: "16px", sm: "32px", md: "58px" };

export const NAVBAR_HEIGHT = "100px";

export const isEnterprise = () => {
  if (!window || !window.location) return false;
  let url = window.location.href;
  if (url.includes("metastreamenterprises.")) {
    return true;
  }
  return false;
};

export const appGreen = isEnterprise() ? "#B1EB23" : "#61D28F";
export const appBackground = isEnterprise() ? "#0D0D32" : "#000000"; //#0D0D32

/*
FIRST  #0D0D32
SEC #1C2833
#323232
*/

export const TEXT_NOT_SELECTABLE = {
  WebkitUserSelect: "none",
  WebkitTouchCallout: "none",
  msTouchSelect: "none",
  MozUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
};

export const HIGHLIGHT_REMOVED = {
  WebkitTapHighlightColor: "transparent",
};

export const NOT_SELECTABLE = {
  ...TEXT_NOT_SELECTABLE,
  ...HIGHLIGHT_REMOVED,
};

const themeFile = () => {
  return {
    palette: {
      primary: {
        main: `${appBlack}`,
        light: `${appBlack}`,
        dark: `${appBlack}`,
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff6333",
        main: "#ff3d00",
        dark: "#b22a00",
        contrastText: "#fff",
      },
      error: {
        light: appRed,
        main: appRed,
        dark: appRed,
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          color: "white",
          variantMapping: {
            logo: "p",
          },
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            textTransform: "none",
            ...(ownerState.variant === "logo" && {
              fontSize: "14px",
              fontWeight: "bold",
              textTransform: "none",
              lineHeight: "16px",
              color: "#fbfbfb",
              textAlign: "left",
            }),
          }),
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          //disableFocusRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({}),
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            padding: 0,
          }),
        },
      },
    },
    typography: {
      color: "white",
      useNextVariants: true,
    },
  };
};
export default themeFile;
