import { isEnterprise } from "../Design/theme";

// SETTING / CHANGING LANGUAGE
function isEng(href) {
  if (!href) return false;
  if (href.includes("metastream.eu") || href.includes("/?lang=en")) return true;
  return false;
}

function getBrowserLang() {
  if (navigator && navigator.language) {
    if (navigator.language.startsWith("cs")) return "cs";
    else return "en";
  } else {
    return "cs";
  }
}

export function getLanguage() {
  if (!window || !window.localStorage) throw new Error("No localStorage");

  let lang = window.localStorage.getItem("lang");
  if (!lang) {
    let url = window.location.href;
    if (isEng(url)) return "en";
    return getBrowserLang();
  } else {
    return lang;
  }
}

export function setLanguage(lang) {
  if (!window || !window.localStorage) throw new Error("No localStorage");
  window.localStorage.setItem("lang", lang);
}

// LANGUAGE DICTIONARY
//isEnterprise() ? '' :
const texts = {
  title: {
    en: isEnterprise() ? "Metastream Enterprises" : "The future is digital",
    cs: isEnterprise() ? "Metastream Enterprises" : "Budoucnost je digitální",
  },
  smallTitle: {
    en: isEnterprise() ? (
      "We are a Czech IT company"
    ) : (
      <>
        And we want to be part of it.
        <br />
        We are Metastream, a Czech IT company.
      </>
    ),
    cs: isEnterprise() ? (
      "Jsme tuzemská IT společnost"
    ) : (
      <>
        A my toužíme být její součástí.
        <br />
        Jsme Metastream, tuzemská IT společnost
      </>
    ),
  },
  aboutButton: {
    en: "About us",
    cs: "O nás",
  },
  about: {
    en: "We develop useful software",
    cs: "Vyvíjíme užitečný software",
  },
  aboutText: {
    en: "In our company we develop software for mobile and computer interfaces. Our purpose is to create useful software for the widest possible audience.",
    cs: "V naší společnosti vyvíjíme software pro mobilní i počítačová rozhraní. Smyslem je pro nás tvořit užitečný software pro co nejširší publikum.",
  },
  aboutTextGreen: {
    en: "In application development, we mainly focus on the fields of education, law and accounting.",
    cs: "Ve vývoji aplikací se zaměřujeme zejména na oblast vzdělání, práva a účetnictví.",
  },
  who: {
    en: "Who are we?",
    cs: "Kdo jsme?",
  },
  ///// ABOUT US
  quest1: {
    en: "We are a Czech company",
    cs: "Jsme česká společnost",
  },
  quest1Text: {
    en: isEnterprise()
      ? "We are based in the Czech Republic in Brno, at Högrova 2862/2, Královo Pole, 612 00 Brno. We are registered with the Regional Court in Brno, Section C 134709, ID No.: 19462395."
      : "We are based based in the Czech Republic in Brno at Högrova 2862/2, Královo Pole, 612 00 Brno. We are registered with the Regional Court in Brno, Section C, Insert 113439, ID No.: 08393494, VAT No.: CZ08393494.",
    cs: isEnterprise()
      ? "Naše společnost má sídlo v České republice ve městě Brně na adrese Högrova 2862/2, Královo Pole, 612 00 Brno. Jsme registrováni u Krajského soudu v Brně, oddíl C, vložka 134709, IČO: 19462395."
      : "Naše společnost má sídlo v České republice ve městě Brně na adrese Högrova 2862/2, Královo Pole, 612 00 Brno. Jsme registrováni u Krajského soudu v Brně, oddíl C, vložka 113439, IČO: 08393494, DIČ: CZ08393494.",
  },
  quest2: {
    en: "We are part of a larger corporate entity",
    cs: "Jsme součástí většího celku",
  },
  quest2Text: {
    en: isEnterprise()
      ? "We are a subsidiary of Metastream s.r.o., with registered office in Brno, Högrova 2862/2, ID No.: 08393494, VAT No.: CZ08393494. Metastream s.r.o. is our parent company. This information is a corporate declaration within the meaning of Section 79(3) of Act No. 90/2012 Coll. on Companies and Cooperatives."
      : "We are the parent company of Metastream Enterprises s.r.o., with its registered office in Brno, Högrova 2862/2, ID No.: 19462395. Metastream Enterprises s.r.o. is our subsidiary. We are the parent company of Profaktura s.r.o., with its registered office in Brno, Högrova 2862/2, ID No.: 21594830. Profaktura s.r.o. is our subsidiary. This information is a declaration of concern within the meaning of Section 79(3) of Act No. 90/2012 Coll. on Companies and Cooperatives.",
    cs: isEnterprise()
      ? "Jsme dceřinou společností společnosti Metastream s.r.o. se sídlem v Brně na ulici Högrova 2862/2, IČO: 08393494, DIČ: CZ08393494. Metastream s.r.o. je naší mateřskou společností. Tato informace je koncernovým prohlášením ve smyslu ust. § 79 odst. 3 zákona č. 90/2012 Sb. o obchodních společnostech a družstvech."
      : "Jsme mateřskou společností společnosti Metastream Enterprises s.r.o. se sídlem v Brně na ulici Högrova 2862/2, IČO: 19462395. Metastream Enterprises s.r.o. je naší dceřinou společností. Jsme mateřskou společností společnosti Profaktura s.r.o. se sídlem v Brně na ulici Högrova 2862/2, IČO: 21594830. Profaktura s.r.o. je naší dceřinou společností. Tyto informace je koncernovým prohlášením ve smyslu ust. § 79 odst. 3 zákona č. 90/2012 Sb. o obchodních společnostech a družstvech.",
  },
  quest3: {
    en: isEnterprise()
      ? "We are moving forward from 2023"
      : "We are moving forward since 2019",
    cs: isEnterprise()
      ? "Kupředu od roku 2023"
      : "Jsme s vámi již od roku 2019",
  },
  quest3Text: {
    en: isEnterprise()
      ? "We founded our company in 2023 and despite the difficult times, we are moving forward boldly."
      : "We established our company in the autumn of 2019 and have come a long way since then. ",
    cs: isEnterprise()
      ? "Naši společnost jsme založili v roce 2023 a navzdory těžkým časům jdeme směle kupředu."
      : "Naši společnost jsme založili na podzim roku 2019 a od té doby jsme ušli už pořádný kus cesty. ",
  },
  ///// CONTACT
  contact: {
    en: "Contact",
    cs: "Kontakt",
  },
  headquarters: {
    en: "Headquarters",
    cs: "Sídlo",
  },
  ICO: {
    en: "ID",
    cs: "IČO",
  },
  DIC: {
    en: "VAT",
    cs: "DIČ",
  },
  ///// ICONS
  question: {
    en: "About us",
    cs: "O nás",
  },
  add: {
    en: "Contact",
    cs: "Kontakt",
  },
  lang: {
    en: "Language",
    cs: "Změnit jazyk",
  },
};

export const getText = (key, lang = getLanguage()) => {
  return texts[key][lang];
};
