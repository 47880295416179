import React, { lazy } from "react";
import Loadable from "./Loaders/Loadable";
import ZasadySoukromi from "../Components/Pages/ZasadySoukromi";
//import MainPage from "../Components/Pages/MainPage";

const MainPage = Loadable(lazy(() => import("../Components/Pages/MainPage")));

let baseRoutes = [
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/zasady",
    element: <ZasadySoukromi />,
  },
];

const routes = [
  ...baseRoutes,
  {
    path: "*",
    element: <MainPage />,
  },
];

export default routes;
