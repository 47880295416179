import { Container } from "@mui/material";
import React from "react";

const white = "rgb(255, 255, 255)";

export default function ZasadySoukromi() {
  return (
    <Container maxWidth="md" sx={{}}>
      <div style={{ height: "150px" }} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h1
          style={{
            textAlign: "left",
            marginTop: "0cm",
            fontSize: "24px",
            fontWeight: "bold",
            color: white,
            fontSize: "28px",
          }}
        >
          Zásady ochrany soukromí
        </h1>
        <p style={{ textAlign: "left", color: white }}>
          <strong>Společnost:</strong> Metastream s.r.o.
        </p>
        <p style={{ textAlign: "left", color: white }}>
          <strong>IČO:</strong> 08393494
        </p>
        <p style={{ textAlign: "left", color: white }}>
          <strong>Adresa:</strong> Metastream s.r.o., Brno, Högrova 2862/2
        </p>
        <p style={{ textAlign: "left", color: white }}>
          Tyto Zásady ochrany soukromí popisují, jak společnost Metastream
          s.r.o. (dále jen „Společnost“) shromažďuje, používá a sdílí vaše
          osobní údaje. Tyto Zásady se vztahují na všechny mobilní aplikace,
          které Společnost provozuje (dále jen „Služba“).
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>Informace, které shromažďujeme</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            Aplikace může shromažďovat následující typy osobních údajů:
          </span>
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Informace, které nám poskytnete:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Jedná se o informace, které nám dobrovolně poskytnete při
              používání Aplikace, jako je vaše jméno, e-mailová adresa,
              telefonní číslo, profilový obrázek a další informace.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Informace o vašem zařízení:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Aplikace může shromažďovat informace o vašem zařízení, jako
              je jeho typ, operační systém, ID zařízení a IP adresa.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Informace o používání Aplikace:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Aplikace může shromažďovat informace o tom, jak používáte
              Aplikaci, jako je to, které funkce používáte, jak často Aplikaci
              používáte a s jakými daty v Aplikaci pracujete.
            </span>
          </p>
        </div>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>Jak používáme vaše osobní údaje</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            Vaše osobní údaje můžeme používat pro následující účely:
          </span>
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Poskytování služeb Aplikace:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Vaše osobní údaje používáme k tomu, abychom vám mohli
              poskytovat funkce a služby Aplikace.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Zlepšování Aplikace:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Vaše osobní údaje používáme k tomu, abychom zlepšovali
              funkce a služby Aplikace a abychom vám nabídli relevantnější a
              personalizovanější zážitek.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Komunikace s vámi:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Vaše osobní údaje používáme k tomu, abychom s vámi
              komunikovali ohledně Aplikace, včetně novinek, aktualizací a
              zákaznické podpory.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Pro reklamní účely:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Vaše osobní údaje můžeme používat k zobrazování reklam,
              které jsou pro vás relevantnější.
            </span>
          </p>
        </div>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>Sdílení vašich osobních údajů</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            Vaše osobní údaje můžeme sdílet s následujícími subjekty:
          </span>
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Poskytovatelé služeb:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Vaše osobní údaje můžeme sdílet s třetími stranami, které
              nám pomáhají provozovat Aplikaci, jako jsou poskytovatelé
              cloudových služeb a analytické společnosti.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Orgány dohledu:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Vaše osobní údaje můžeme sdílet s orgány dohledu, pokud je
              to vyžadováno zákonem.
            </span>
          </p>
        </div>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>Vaše práva</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            Máte následující práva týkající se vašich osobních údajů:
          </span>
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Přístup k vašim osobním údajům:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Máte právo na přístup k vašim osobním údajům, které o vás
              shromažďujeme.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Oprava vašich osobních údajů:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Máte právo na opravu vašich osobních údajů, pokud jsou
              nesprávné nebo neúplné.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Výmaz vašich osobních údajů:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Máte právo požádat o výmaz vašich osobních údajů v určitých
              případech.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Omezení zpracování vašich osobních údajů:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Máte právo omezit zpracování vašich osobních údajů v
              určitých případech.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Námitka proti zpracování vašich osobních údajů:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Máte právo vznést námitku proti zpracování vašich osobních
              údajů v určitých případech.
            </span>
          </p>
        </div>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>Odhlášení z marketingových sdělení</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            Můžete se kdykoli odhlásit z odběru marketingových sdělení od nás.
            To provedete tak, že kliknete na odkaz „Odhlásit se“ v dolní části
            marketingového e-mailu nebo v nastavení Aplikace.
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>Sdílení dat se společnostmi Apple a Google</strong>
          </span>
        </p>
        <p style={{ textAlign: "left", fontSize: "20px" }}>
          <span style={{ color: white }}>
            <strong>Apple:</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            Aplikace používají některé služby společnosti Apple, které můžou
            shromažďovat a sdílet vaše data. Patří mezi ně:
          </span>
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Apple App Store:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Společnost Apple shromažďuje informace o vašem zařízení a o
              tom, jak používáte Aplikaci, aby vám mohla poskytovat služby App
              Store.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Apple iCloud:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Pokud používáte iCloud k zálohování dat Aplikace, vaše data
              budou sdílena se společností Apple.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Apple Push Notifications:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Společnost Apple shromažďuje informace o vašem zařízení a o
              tom, jak používáte Aplikaci, aby vám mohla posílat push
              notifikace.
            </span>
          </p>
        </div>
        <p style={{ textAlign: "left", fontSize: "20px" }}>
          <span style={{ color: white }}>
            <strong>Google:</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            Aplikace používá některé služby společnosti Google, které můžou
            shromažďovat a sdílet vaše data. Patří mezi ně:
          </span>
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Google Play Store:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Společnost Google shromažďuje informace o vašem zařízení a o
              tom, jak používáte Aplikaci, aby vám mohla poskytovat služby
              Google Play.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Google Firebase:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Společnost Google shromažďuje informace o vašem zařízení a o
              tom, jak používáte Aplikaci, aby vám mohla poskytovat funkce a
              služby Firebase.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Google Analytics:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Společnost Google shromažďuje informace o vašem zařízení a o
              tom, jak používáte Aplikaci, aby mohla analyzovat používání
              Aplikace a zlepšovat její funkce.
            </span>
          </p>
        </div>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>Další informace:</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            Další informace o tom, jak Apple a Google shromažďují a používají
            vaše data, naleznete v jejich zásadách ochrany osobních údajů:
          </span>
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Apple:</strong>
            </span>
            <span style={{ color: white }}>&nbsp;</span>
            <a href="https://www.apple.com/privacy/" target="_blank">
              <span style={{ color: "rgb(0, 0, 255)" }}>
                <u>https://www.apple.com/privacy/</u>
              </span>
            </a>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Google:</strong>
            </span>
            <span style={{ color: white }}>&nbsp;</span>
            <a href="https://policies.google.com/privacy" target="_blank">
              <span style={{ color: "rgb(0, 0, 255)" }}>
                <u>https://policies.google.com/privacy</u>
              </span>
            </a>
          </p>
        </div>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>Důležité:</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            Společnost Apple a Google můžou používat vaše data k vlastním
            účelům, které nejsou popsány v těchto Zásadách. Před použitím
            Aplikace si prosím přečtěte zásady ochrany osobních údajů
            společností Apple a Google.
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>Kontaktní informace:</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            Máte-li jakékoli dotazy ohledně těchto Zásad ochrany soukromí,
            neváhejte nás kontaktovat:
          </span>
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>E-mail:</strong>
            </span>
            <span style={{ color: white }}>&nbsp;info@metastream.cz</span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Adresa:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Metastream s.r.o., Brno, Högrova 2862/2
            </span>
          </p>
        </div>
        <p style={{ textAlign: "left" }}>
          <br />
          &nbsp;
        </p>
        <p style={{ textAlign: "left" }}>
          <strong>Účinnost:</strong> 2. února 2022
        </p>
        <p
          style={{
            textAlign: "left",
            marginLeft: "0.64cm",
          }}
        >
          <br />
        </p>
      </div>
    </Container>
  );
}
