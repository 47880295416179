import React, { Component } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { isEnterprise } from "../../Design/theme";

const preTags = ["", "twitter:", "og:"];

const metaData = {
  title: "Metastream s.r.o. | Czech IT Company",
  description:
    "Metastream s.r.o | info@metastream.cz | Högrova 2862/2 | Královo Pole | 612 00 Brno | IČO: 08393494 | DIČ: CZ08393494",
};
const metaEnterData = {
  title: "Metastream Enterprises s.r.o. | Czech IT Company",
  description:
    "Metastream Enterprises s.r.o | info@metastream.cz | Högrova 2862/2 | Královo Pole | 612 00 Brno | IČO: 19462395",
};

function getData() {
  if (isEnterprise()) {
    return metaEnterData;
  }
  return metaData;
}

export default class HelmetComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {preTags.map((tag, index) => {
          if (tag !== "") {
            return (
              <Helmet key={"HelmetMeta" + index}>
                <meta name={tag + "title"} content={getData().title} />
                <meta
                  name={tag + "description"}
                  content={getData().description}
                />
              </Helmet>
            );
          } else {
            return (
              <Helmet key={"HelmetMeta" + index}>
                <title>{getData().title}</title>
                <meta name="title" content={getData().title} />
                <meta name="description" content={getData().description} />
              </Helmet>
            );
          }
        })}
      </>
    );
  }
}
