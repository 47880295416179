import "./App.css";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import themeFile, {
  appBackground,
  isEnterprise,
  NAVBAR_HEIGHT,
} from "./Resources/Design/theme";
import routes from "./Resources/Router/router";
import {
  cloneElement,
  createContext,
  lazy,
  useEffect,
  useRef,
  useState,
} from "react";
import FramerLoadingPage from "./Resources/Router/Loaders/FramerLoadingPage";
import { getLanguage, setLanguage } from "./Resources/Classes/Language";
import Loadable from "./Resources/Router/Loaders/Loadable";
import HelmetComponent from "./Resources/Components/Base/HelmetComponent";
import { HelmetProvider } from "react-helmet-async";

//import Navbar from "./Resources/Components/Bars/Navbar";
//import Footer from "./Resources/Components/Bars/Footer";

const Navbar = Loadable(
  lazy(() => import("./Resources/Components/Bars/Navbar"))
);
const Footer = Loadable(
  lazy(() => import("./Resources/Components/Bars/Footer"))
);

export const LanguageContext = createContext();

export const theme = createTheme(themeFile());

const PageComponent = ({ route }) => {
  const kdoJsmeRef = useRef(null);
  const kontaktRef = useRef(null);

  const props = {
    kdoJsmeRef: kdoJsmeRef,
    kontaktRef: kontaktRef,
  };

  useEffect(() => {
    if (window) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    }
  }, []);

  return (
    <div
      style={{
        minWidth: "310px",
        minHeight: "100vh",
      }}
    >
      <HelmetComponent />
      <Navbar kontaktRef={kontaktRef} kdoJsmeRef={kdoJsmeRef} />
      <div style={{}}>{cloneElement(route.element, props)}</div>
      <Footer />
    </div>
  );
};

const getRoutes = () => {
  return [
    ...routes.map((route) => ({
      path: route.path,
      element: <PageComponent route={route} />,
      errorElement: <></>,
    })),
  ];
};

// Changes header sources to enterprise
function changeToEnterprise() {
  if (!document) return;

  const sources = [
    {
      id: "logo-selector",
      meta: "metaLogo256.png",
      enter: "enterLogo256.png",
      rel: "apple-touch-icon",
    },
    {
      id: "icon-selector",
      meta: "metaFavicon.ico",
      enter: "enterFavicon.ico",
      rel: "icon",
    },
    {
      id: "manifest-selector",
      meta: "metaManifest.json",
      enter: "enterManifest.json",
      rel: "manifest",
    },
  ];

  for (let i = 0; i < sources.length; i++) {
    let link = document.querySelector(`#${sources[i].id}`);
    let hrefSave = link.href;
    let href = hrefSave.replaceAll(sources[i].meta, sources[i].enter);
    link.href = href;
  }
}

function App() {
  const [language, setLangContext] = useState(getLanguage());
  const [head, setHead] = useState(null);

  function changeLanguage() {
    let changedLang = "en";
    if (language == "en") {
      changedLang = "cs";
    }
    setLanguage(changedLang);
    setLangContext(changedLang);
  }

  useEffect(() => {
    if (isEnterprise()) {
      changeToEnterprise();
    }
  });

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Outlet />,
      children: getRoutes(),
    },
  ]);

  return (
    <div className="App" style={{ backgroundColor: appBackground }}>
      <HelmetProvider>
        {head || null}
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <LanguageContext.Provider
            value={{ lang: language, setLang: changeLanguage }}
          >
            <RouterProvider router={router} />
          </LanguageContext.Provider>
        </ThemeProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
