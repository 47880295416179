import { Box } from "@mui/material";
import { motion } from "framer-motion";
import Logo from "../../Design/Logo";
import { appBackground } from "../../Design/theme";

export default function FramerLoadingPage() {
  return (
    <div style={{}}>
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          bgcolor: appBackground,
          opacity: 0.9,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          position: "fixed",
          overflow: "hidden",
          left: 0,
          top: 0,
          zIndex: "999999 !important",
        }}
      >
        <motion.div
          style={{
            width: "140px",
            height: "140px",
            color: "white",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            borderRadius: "10%",
          }}
          className="box"
          animate={{
            scale: [1, 1.3, 1.3, 1, 1],
            rotate: [0, 0, 180, 180, 0],
            borderRadius: ["10%", "10%", "50%", "50%", "10%"],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1],
            repeat: Infinity,
            repeatDelay: 0,
          }}
        />
        <motion.div
          style={{
            width: "70px",
            height: "70px",
            position: "absolute",
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
          }}
          animate={{
            scale: [1, 1.2, 1.2, 1, 1],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: [0, 0.2, 0.5, 0.8, 1],
            repeat: Infinity,
            repeatDelay: 0,
          }}
        >
          <Logo color={appBackground} unhide={true} />
        </motion.div>
      </Box>
    </div>
  );
}
